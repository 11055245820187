import React from 'react';


function Projects() {
  return (
        <div>
         <h1>I am About</h1>
         <p >
          Welcome to IMC, where innovation meets excellence! We
          are a dynamic marketing company committed to transforming your digital
          presence and driving your business growth. Indian Marketing Company is Specializing in a range of
          services, including web development, digital marketing, SEO, and SaaS
          app development, we tailor solutions that align perfectly with your
          unique goals and objectives.
        </p>
        </div>
  );
}
    
export default Projects;