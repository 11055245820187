import React from 'react'

const Myskills = () => {
  return (
    <>
 <h2 className="ot-title" style={{color:"#fff", textAlign:"start"}}><span className="mini-title">MY SKILLS</span><br />Technologies.    </h2>
    <div className='d-flex flex-wrap justify-content-center gap-5 mt-4'>
        <div className="football"> HTML</div>
        <div className="football"> CSS</div>
        <div className="football"> JS</div>
        <div className="football"> Reactjs</div>
        <div className="football"> TS</div>
        <div className="football"> Node</div>
        <div className="football"> helo</div>
    </div>
    <div className='d-flex flex-wrap justify-content-center gap-5 mt-5'>
        <div className="football"> helo</div>
        <div className="football"> helo</div>
        <div className="football"> helo</div>
        <div className="football"> helo</div>
        <div className="football"> helo</div>
        <div className="football"> helo</div>
        <div className="football"> helo</div>
    </div>
    </>
  )
}

export default Myskills
